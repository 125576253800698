<template>
    
    <tabla-datos :botones="true" :columnas="columnas" :datos="expedientes" :filtros="filtros"
        :filtrosBuscador="filtrosBuscador" :total="total" @quieroDatos="obtenerExpedientes($event)" >
    </tabla-datos>
   
   </template>
   
   <script>
   import { PwgsApi } from '../../../services/PwgsApi';
   import TablaDatos from '../../TablaDatos.vue';
   import { FilterMatchMode } from 'primevue/api';
   export default {
       components: {
         
           "tabla-datos": TablaDatos,
       },
       data() {
           return {
               total: '',
               expedientes: [],
               parametros: '',
               filtrosBuscador: ['codigo_servicio'],
               filtros: {
                   'codigo_servicio': { value: "", matchMode: FilterMatchMode.CONTAINS },
                   
                   
               },
               columnas: [
                   {
                       header:'Tipo',
                       field: 'class',

                   },
                   {
                       header: 'Expediente',
                       field: 'codigo_servicio',
                       sortable: true,
                       acciones: [
                           {
                               link: true,
                               tipo: 'default',
                               nombreId: 'id_servicio',
                               // Debe ser arrow function para que this sea este componente
                               accion: (id) => {
                                   console.log('Editar expediente ' + id);
                                   this.$router.push({
                                       name: 'Servicio',
                                       params: {
                                           id: id
                                       }
                                   });
                               }
                           },
                       ]
                   },
                   {
                       header: 'Compañia',
                       field: 'nombre_compania',
                       sortable: true,
                   },
                   {
                       header: 'Fecha/Hora',
                       field: 'fecha_hora',
                       sortable: true,
                   },
                   {
                       header: 'Gremio',
                       field: 'gremio_servicio',
                       sortable: true,
                   },
                   {
                       header: 'Direción',
                       field: 'direccion_cliente',
                       sortable: true,
                   },

                   {
                       header: 'Contacto',
                       field: 'telefonos_cliente',
                       sortable: true,
                   },
                   {
                       header: 'Tramitador',
                       field: 'nombre_tramitador',
                       sortable: true,
                   },
                   {
                       data: null,
                       acciones: [
                           {
                               nombre: 'Editar',
                               class: 'editar',
                               tipo: 'default',
                               nombreId: 'id_servicio',
                               icono: 'far fa-edit',
                               // Debe ser arrow function para que this sea este componente
                               accion: (id) => {
                                   console.log('Editar expediente ' + id);
                                   this.$router.push({
                                       name: 'Servicio',
                                       params: {
                                           id:id
                                       }
                                   });
                               }
                           },
                       ]
                   }
               ]
           }
       },
       methods: {
           //https://sonia.pwgs.app/modulos/pwgsapi/index.php/listados-servicios/activos
           async obtenerExpedientes(event) { 
               const api = new PwgsApi;
               const aux = await api.post('avisos/asignaciones',event);
               this.expedientes = aux.datos;
               this.total = aux.n_total_registros;
               this.$emit('evento_total_asignaciones', this.total); //Es llamado por ./Escritorio.vue
           },
       },
    
           mounted() {
          // this.obtenerExpedientes();
       }
   }
   </script>
   <style>
   .p-dialog {
       width: 90%;
   }
   .p-datatable-header{
   
       visibility:hidden;
   }
   
   </style>